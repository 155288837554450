import namedKeys from 'fela-plugin-named-keys';
import { FelaCSS } from '../components/fela/flowtypes';
import { screenSizes } from '../themes/variables';

const mediaQueryMap = {
  /**
   * EDGE-CASE ONLY
   * This should only be used when we need to support a screen smaller than smallest mobile.
   */
  minWidthMobile: `@media (min-width: ${screenSizes.mobileSm}px)`,
  /**
   * EDGE-CASE ONLY
   * This should only be used when we need to support a screen smaller than smallest mobile.
   * By default, styles should be defined for mobile first, and then adjusted for larger screens.
   */
  onlyWidthMobile: `@media (min-width: ${screenSizes.mobileSm}px) and (max-width: ${screenSizes.mobileLgMax}px)`,
  // For large mobile AND tablet AND desktop
  minWidthMobileLg: `@media (min-width: ${screenSizes.mobileLg}px)`,
  // ONLY for large mobile
  onlyWidthMobileLg: `@media (min-width: ${screenSizes.mobileLg}px) and (max-width: ${screenSizes.mobileLgMax}px)`,
  // For tablet AND desktop
  minWidthTablet: `@media (min-width: ${screenSizes.tabletSm}px)`,
  // For large tablet AND desktop
  minWidthTabletLg: `@media (min-width: ${screenSizes.tabletLg}px)`,
  // ONLY for tablet
  onlyWidthTablet: `@media (min-width: ${screenSizes.tabletSm}px) and (max-width: ${screenSizes.tabletLgMax}px)`,
  // ONLY for small tablet
  onlyWidthTabletSm: `@media (min-width: ${screenSizes.tabletSm}px) and (max-width: ${screenSizes.tabletSmMax}px)`,
  // ONLY for large tablet
  onlyWidthTabletLg: `@media (min-width: ${screenSizes.tabletLg}px) and (max-width: ${screenSizes.tabletLgMax}px)`,
  // ONLY for desktop
  minWidthDesktop: `@media (min-width: ${screenSizes.desktopSm}px)`,
  // ONLY for small desktop
  onlyWidthDesktopSm: `@media (min-width: ${screenSizes.desktopSm}px) and (max-width: ${screenSizes.desktopSmMax}px)`,
  // ONLY for large desktop
  minWidthDesktopLg: `@media (min-width: ${screenSizes.desktopLg}px)`,

  /** @deprecated - style should be from 0 by default and overwritten with larger screen */
  superSmallMobile: `@media (max-width: ${screenSizes.mobileSm - 1}px)`,
  /** @deprecated - styles should be mobile by default and overwritten for larger screen */
  maxWidthMobileSm: `@media (max-width: ${screenSizes.mobileSmMax}px)`,
  /** @deprecated - styles should be mobile by default and overwritten for larger screen */
  maxWidthMobile: `@media (max-width: ${screenSizes.mobileLgMax}px)`,
  /** @deprecated - styles should be mobile by default and overwritten for larger screen */
  maxWidthTabletSm: `@media (max-width: ${screenSizes.tabletSmMax}px)`,
  /** @deprecated - styles should be mobile by default and overwritten for larger screen */
  maxWidthTablet: `@media (max-width: ${screenSizes.tabletLgMax}px)`,
  /** @deprecated - use minWidthDesktop instead */
  desktopDesign: `@media only screen and (min-width: ${screenSizes.tabletLg}px), screen and (orientation:landscape) and (min-width: ${screenSizes.tabletSm}px)`,
  supportsSafeAreaInset: '@supports (bottom: env(safe-area-inset-bottom))',
  // @see https://medium.com/@mezoistvan/finally-a-css-only-solution-to-hover-on-touchscreens-c498af39c31c
  noTouchDevices: '@media(hover: hover) and (pointer: fine)',
  msEdgeOnly: '@supports (-ms-ime-align: auto)',
  landscape: '@media (orientation: landscape)',
  portrait: '@media (orientation: portrait)',
};

const containerQueryMap = {
  /**
   * EDGE-CASE ONLY
   * This should only be used when we need to support a screen smaller than smallest mobile.
   */
  containerMinWidthMobile: `@container (min-width: ${screenSizes.mobileSm}px)`,
  /**
   * EDGE-CASE ONLY
   * This should only be used when we need to support a screen smaller than smallest mobile.
   * By default, styles should be defined for mobile first, and then adjusted for larger screens.
   */
  containerOnlyWidthMobile: `@container (min-width: ${screenSizes.mobileSm}px) and (max-width: ${screenSizes.mobileLgMax}px)`,
  // For large mobile AND tablet AND desktop
  containerMinWidthMobileLg: `@container (min-width: ${screenSizes.mobileLg}px)`,
  // ONLY for large mobile
  containerOnlyWidthMobileLg: `@container (min-width: ${screenSizes.mobileLg}px) and (max-width: ${screenSizes.mobileLgMax}px)`,
  // For tablet AND desktop
  containerMinWidthTablet: `@container (min-width: ${screenSizes.tabletSm}px)`,
  // For large tablet AND desktop
  containerMinWidthTabletLg: `@container (min-width: ${screenSizes.tabletLg}px)`,
  // ONLY for tablet
  containerOnlyWidthTablet: `@container (min-width: ${screenSizes.tabletSm}px) and (max-width: ${screenSizes.tabletLgMax}px)`,
  // ONLY for small tablet
  containerOnlyWidthTabletSm: `@container (min-width: ${screenSizes.tabletSm}px) and (max-width: ${screenSizes.tabletSmMax}px)`,
  // ONLY for large tablet
  containerOnlyWidthTabletLg: `@container (min-width: ${screenSizes.tabletLg}px) and (max-width: ${screenSizes.tabletLgMax}px)`,
  // ONLY for desktop
  containerMinWidthDesktop: `@container (min-width: ${screenSizes.desktopSm}px)`,
  // ONLY for small desktop
  containerOnlyWidthDesktopSm: `@container (min-width: ${screenSizes.desktopSm}px) and (max-width: ${screenSizes.desktopSmMax}px)`,
  // ONLY for large desktop
  containerMinWidthDesktopLg: `@container (min-width: ${screenSizes.desktopLg}px)`,
};

export type FelaNamedMediaQueriesProperties = {
  [Properties in keyof typeof mediaQueryMap]?: FelaCSS;
};

export type FelaNamedContainerQueriesProperties = {
  [Properties in keyof typeof containerQueryMap]?: FelaCSS;
};

const queryMaps = {
  ...mediaQueryMap,
  ...containerQueryMap,
};

export default namedKeys(queryMaps);
